import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../components/layout/layout';
import axios from 'axios';
import NProgress from 'nprogress';
import { NotFoundPage } from '../global.css';
import { allTrialsIds } from '../constants/config';
import { StaticQuery, graphql } from 'gatsby';
import { Collapse } from 'reactstrap';
import { FaMinusCircle, FaPlusCircle } from 'react-icons/fa';

class Sitemap extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      scriptsLoading: true,
      urls: [],
      subMenuOpen: false,
      companyName: '',
    };
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      let companyName =
        window.location.hostname.indexOf('merckgroup') > 0 ? 'merck' : 'emd';
      this.setState({
        scriptsLoading: false,
        companyName: companyName,
      });
    }

    this.loadData();
  }

  loadData() {
    NProgress.start();
    axios
      .get(allTrialsIds())
      .then((response) => {
        NProgress.done();
        if (response.data && response.data.Success) {
          this.setState({
            urls: response.data.Data,
          });
        }
      })
      .catch(() => {
        NProgress.done();
      });
  }

  render() {
    return this.state.scriptsLoading ? (
      ''
    ) : (
      <Layout
        meta={{
          pageTitle: 'Site Map',
          pageDescription:
            'Find information about Clinical Trials & Observational Studies that are in progess for numerous cancer types and conditions.',
        }}
      >
        <NotFoundPage className={'full-width'}>
          <h1>Sitemap</h1>
          <ul>
            {this.props.data.pageMetadataJson.pages
              .filter((page) => page.url.indexOf('sitemap') < 0)
              .map((url) =>
                url.url.includes(`${this.state.companyName}`) ||
                url.url.indexOf('http') < 0 ? (
                  <li key={url.url}>
                    <a
                      href={
                        url.url.indexOf('http') === 0
                          ? url.url
                          : `/${url.pageLang}/${url.url}`
                      }
                    >
                      {url.menuTitle}
                      <span className={'accesibility-features'}>
                        Accesibilty
                      </span>
                    </a>
                  </li>
                ) : null
              )}
          </ul>
          <div className={'details-section'}>
            <button
              onClick={() => {
                this.setState({ subMenuOpen: !this.state.subMenuOpen });
              }}
            >
              {'Trial Details Pages'}
              {this.state.subMenuOpen ? <FaMinusCircle /> : <FaPlusCircle />}
            </button>
            <Collapse isOpen={this.state.subMenuOpen}>
              <ul>
                {this.state.urls.map((url) => (
                  <li className={'sub-items'} key={url.Id}>
                    <a href={`/en/trial-details/?id=${url.UniqueIdentifier}`}>
                      {'Trial Details Page, ID number : ' +
                        url.UniqueIdentifier}
                    </a>
                  </li>
                ))}
              </ul>
            </Collapse>
          </div>
        </NotFoundPage>
      </Layout>
    );
  }
}

const SitemapWithQuery = (props) => (
  <StaticQuery
    query={graphql`
      query {
        pageMetadataJson {
          pages {
            menuTitle
            url
            pageLang
          }
        }
      }
    `}
    render={(data) => <Sitemap data={data} {...props} />}
  />
);

export default SitemapWithQuery;

Sitemap.propTypes = {
  data: PropTypes.object.isRequired,
};
